<template>
  <div>
    <div class="row">
      <div class="col-12">
        <c-card title="화학자재 상세정보" class="cardClassDetailInfo">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
              <div class="row q-pa-md">
                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                  <c-chem-material 
                    :editable="editable"
                    :required="true"
                    type="edit"
                    name="mdmChemMaterialId"
                    v-model="inout.mdmChemMaterialId"
                    @chemMaterialInfo="chemMaterialInfo"
                  />
                </div>
                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <c-select
                    :disabled="true"
                    :editable="editable"
                    codeGroupCd="USAGE_CD"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="usageCd"
                    label="용도"
                    v-model="inout.usageCd"
                  ></c-select>
                </div>
                <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                  <c-datepicker
                    default="today"
                    type="month"
                    label="년월"
                    name="yearmonth"
                    v-model="inout.yearmonth"
                    @datachange="changeYearMonth"
                  />
                </div>
                <div class="col-12" v-for="(item, idx) in reguls" :key="idx"
                  :class="idx===0?'chem-inout-info ':''">
                  <font class="formLabelTitle txtlabel">{{item.label}}</font>
                  <q-space />
                  <q-checkbox
                    v-for="(_item, _idx) in item.child" :key="_idx"
                    dense
                    color="orange-custom"
                    :label="_item.label"
                    true-value="Y"
                    false-value="N"
                    :value="_item.vals"
                    class="customqcbox"
                    :disable="true"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
              <c-table
                ref="table"
                title="구성물질"
                tableId="table1"
                :columns="grid1.columns"
                :data="grid1.data"
                :columnSetting="false"
                :filtering="false"
                :usePaging="false"
                :hideBottom="true"
                gridHeight="220px"
              >
              </c-table>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-table
      ref="inout-table"
      class="q-mt-sm"
      title="보관/저장 관리대장"
      :columns="grid2.columns"
      :data="grid2.data"
      :gridHeight="gridHeight"
      :filtering="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="chmSaveManageId"
      :editable="editable"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="printFlag" label="출력" icon="print" @btnClicked="print" />
          <c-btn v-if="editable&&!disabled" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
          <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" @btnClicked="deleteItem" />
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid2.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="save"
            @btnCallback="saveCallback"/>
        </q-btn-group>
      </template>
      <template slot="suffixTitle">
        <template v-if="!inout.yearmonth">
          <font class="text-negative" style="font-size:0.8em;font-weight:300;">
            ※ 년월을 통해 월별 관리대장을 보실수 있습니다.
          </font>
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'save-management-detail',
  components: {
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
    contentHeight: {
      type: [Number, String],
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      inout: {
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialName: '',
        materialCd: '',  // 자재코드
        yearmonth: '',  // 년월
        makeFlag: 'N',
        impFlag: 'N',
        buyFlag: 'N',
        usingFlag: 'N',
        expFlag: 'N',
        salesFlag: 'N',
      },
      reguls: [],
      grid1: {
        columns: [
          {
            name: 'chemName',
            field: 'chemName',
            label: '화학물질',
            align: 'left',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함유량(%)',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [],
        data: [],
        prevData: [],
      },
      printFlag: false,
      editable: true,
      substanceUrl: '',
      regulUrl: '',
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 310) + 'px';
    },
    disabled() {
      return !this.inout.mdmChemMaterialId;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.substanceUrl = selectConfig.mdm.mam.chem.substance.url;
      this.regulUrl = selectConfig.mdm.mam.chem.header.value.url;
      this.listUrl = selectConfig.chm.save.list.url;
      this.saveUrl = transactionConfig.chm.save.save.url;
      this.deleteUrl = transactionConfig.chm.save.delete.url;
      this.printUrl = selectConfig.chm.save.print.url;
      // code setting
      // list setting
      this.setInout();
    },
    setHeader() {
      this.grid2.columns = [
        {
          name: 'inoutDate',
          field: 'inoutDate',
          label: '입출고일',
          align: 'center',
          style: 'width:140px',
          sortable: false,
          type: 'date',
          start: this.inout.yearmonth ? this.inout.yearmonth + '-01' : '',
          end: this.inout.yearmonth ? this.$comm.moment(this.inout.yearmonth).endOf('month').format('YYYY-MM-DD') : '',
          required: true,
          fix: true,
        },
        {
          name: 'in',
          field: 'in',
          label: '입고량',
          align: 'center',
          child: [
            {
              name: 'carryover',
              field: 'carryover',
              label: '이월량',
              align: 'right',
              style: 'width:70px',
              sortable: false,
            },
            {
              name: 'purchase',
              field: 'purchase',
              label: '위탁인',
              child: [
                {
                  name: 'inVendorCd',
                  field: 'inVendorCd',
                  label: '상호',
                  align: 'center',
                  style: 'width:220px',
                  sortable: false,
                  type: 'vendor',
                  vendorInfo: {
                    vendorName: 'inVendorName',
                    bizNo: 'inCorpRegistrationNumber',
                    address: 'inAddress',
                    chargeName: 'inUserName',
                    phoneNo: 'inPhoneNumber',
                  }
                },
                {
                  name: 'inCorpRegistrationNumber',
                  field: 'inCorpRegistrationNumber',
                  label: '사업자등록번호(생년월일)',
                  align: 'center',
                  style: 'width:110px',
                  sortable: false,
                },
                {
                  name: 'inAddress',
                  field: 'inAddress',
                  label: '주소',
                  align: 'center',
                  style: 'width:200px',
                  sortable: false,
                },
                {
                  name: 'inAmount',
                  field: 'inAmount',
                  label: '수량',
                  align: 'right',
                  style: 'width:70px',
                  sortable: false,
                  type: 'number',
                },
              ]
            },
          ]
        },
        {
          name: 'out',
          field: 'out',
          label: '출고량',
          align: 'center',
          child: [
            {
              name: 'sale',
              field: 'sale',
              label: '위탁인',
              child: [
                {
                  name: 'outVendorCd',
                  field: 'outVendorCd',
                  label: '상호',
                  align: 'center',
                  style: 'width:220px',
                  sortable: false,
                  type: 'vendor',
                  vendorInfo: {
                    vendorName: 'outVendorName',
                    bizNo: 'outCorpRegistrationNumber',
                    address: 'outAddress',
                    chargeName: 'outUserName',
                    phoneNo: 'outPhoneNumber',
                  }
                },
                {
                  name: 'outCorpRegistrationNumber',
                  field: 'outCorpRegistrationNumber',
                  label: '사업자등록번호(생년월일)',
                  align: 'center',
                  style: 'width:110px',
                  sortable: false,
                },
                {
                  name: 'outAddress',
                  field: 'outAddress',
                  label: '주소',
                  align: 'center',
                  style: 'width:200px',
                  sortable: false,
                },
                {
                  name: 'outAmount',
                  field: 'outAmount',
                  label: '수량',
                  align: 'right',
                  style: 'width:70px',
                  sortable: false,
                  type: 'number',
                },
              ]
            },
          ]
        },
        {
          name: 'inventory',
          field: 'inventory',
          label: '재고량',
          align: 'right',
          style: 'width:70px',
          sortable: false,
        },
        {
          name: 'remarks',
          field: 'remarks',
          label: '비고',
          align: 'left',
          style: 'width:150px',
          sortable: false,
          type: 'text',
        },
      ]
    },
    setInout() {
      if (this.popupParam.mdmChemMaterialId) {
        this.inout.mdmChemMaterialId = this.$_.clone(this.popupParam.mdmChemMaterialId)
      }
      this.setHeader();
      this.getList();
      this.getReguls();
    },
    getList(isPrev) {
      if ((!this.inout.mdmChemMaterialId && !this.popupParam.mdmChemMaterialId)) {
        this.printFlag = false;
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.inout.mdmChemMaterialId || this.popupParam.mdmChemMaterialId,
        yearMonth: this.inout.yearmonth,
        prevFlag: !isPrev ? '' : true,
        // facilities: !isPrev ? '' : this.$_.map(this.facilities, 'facilityCd'),
      }
      this.$http.request((_result) => {
        if (!isPrev) {
          this.grid2.data = _result.data;
          this.printFlag = this.grid2.data && this.grid2.data.length > 0
          this.getList(true);
        } else {
          this.grid2.prevData = _result.data;
        }
      },);
    },
    getReguls() {
      this.$http.url = this.regulUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.inout.mdmChemMaterialId || this.popupParam.mdmChemMaterialId,
      };
      this.$http.request((_result) => {
        this.reguls = this.$_.filter(_result.data, (item) => {
          return item.id === 'RBC0000012' || item.id === 'RBC0000009'
        });
      },);
    },
    getSubstance() {
      this.$http.url = this.substanceUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.inout.mdmChemMaterialId || this.popupParam.mdmChemMaterialId,
      };
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          this.$_.forEach(_result.data, item => {
            this.$set(item, 'chemName', item.chemNmEn + '(' + item.chemNmKr + ')')
          })
        }
        this.grid1.data = _result.data;
      },);
    },
    chemMaterialInfo(data) {
      // 규제사항 재조회
      this.getReguls();
      // 구성물질 재조회
      this.getSubstance();
      // 자재 정보 셋팅
      this.$_.extend(this.inout, data)
      this.$set(this.popupParam, 'mdmChemMaterialId', this.inout.mdmChemMaterialId)
    },
    changeYearMonth() {
      this.setHeader();
      this.getList();
    },
    tableDataChange(props, col) {
      if (col.name === 'inoutDate') { //  || col.name === 'facilityCd'
        // 1. 입출고일 2. 저장시설
        //   - 입출고일이 있는지 확인
        //   - 입출고일 & 저장시설 동일 데이터가 있는지 확인
        //   - 전 데이터 기준으로 재고량을 이월량 & 재고량으로 옮기기
        // ** 이전 데이터 중 최신 데이터를 찾는다.
        this.checkDate(props, true);
        this.getList(true)
      } else if (col.name === 'inAmount' || col.name === 'outAmount') {
        //  3. 입고량 4. 출고량
        //   - 입출고일이 있는지 확인
        //   - 재고량 = 재고량 + 입고량
        //   - 입출고일 & 저장시설 기점으로 이후데이터 차례대로 이월량 및 재고량 변경
        //       이월량 : 전 데이터 재고량
        //       재고량 : 전 데이터 이월량 + 입고량 - 출고량
        this.checkDate(props, false);
      } 
    },
    checkDate(props, sameDataCheck) {
      if (props.row.inoutDate) { // props.row.facilityCd && 
        if (sameDataCheck) {
          let filterLen = this.$_.filter(this.grid2.data, { 
            inoutDate: props.row.inoutDate,
          }).length
          if (filterLen > 1) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '입출고일이 동일한 데이터가 존재합니다.', // 입출고일이 동일한 데이터가 존재합니다.
              type: 'warning', // success / info / warning / error
            });
            return;
          }
        }
        let filterData = this.$_.sortBy(this.grid2.data, 'inoutDate')
        if (filterData && filterData.length > 0) {
          let _idx = this.$_.findIndex(filterData, { inoutDate: props.row.inoutDate })
          if (_idx > 0) {
            // 전 데이터가 존재
            this.$set(props.row, 'carryover', Number(filterData[_idx - 1].inventory))
            this.$set(props.row, 'inventory', Number(filterData[_idx - 1].inventory) + Number(props.row.inAmount) - Number(props.row.outAmount))
          } else {
            // 첫 데이터
            this.$set(props.row, 'carryover', 0)
            this.$set(props.row, 'inventory', Number(props.row.inAmount) - Number(props.row.outAmount))
            // }
          }
          if (props.row.editFlag !== 'C') {
            this.$set(props.row, 'editFlag', 'U')
            this.$set(props.row, 'chgUserId', this.$store.getters.user.userId)
          }

          if (!sameDataCheck && _idx + 1 < filterData.length) {
            for (let i = _idx + 1; i < filterData.length; i++) {
              this.$set(filterData[i], 'carryover', Number(filterData[i - 1].inventory))
              this.$set(filterData[i], 'inventory', Number(filterData[i - 1].inventory) + Number(filterData[i].inAmount) - Number(filterData[i].outAmount))
              if (filterData[i].editFlag !== 'C') {
                this.$set(filterData[i], 'editFlag', 'U')
                this.$set(filterData[i], 'chgUserId', this.$store.getters.user.userId)
              }
            }
          }
        }
      }
    },
    add() {
      /**
       * 시설이 필터링 되어 있는 경우
       * 추가버튼 클릭 시 시설을 자동으로 적용
       */
      this.grid2.data.splice(0, 0, {
        chmSaveManageId: uid(),  // 입출고 번호
        mdmChemMaterialId: this.inout.mdmChemMaterialId,  // 화학자재 일련번호
        materialCd: this.inout.materialCd,  // 자재코드
        inoutDate: '',  // 입출고일
        carryover: 0,  // 이월량
        inAmount: 0,  // 입고량
        inVendorCd: '',  // 구입업체코드
        inVendorName: '',  // 구입업체명
        inCorpRegistrationNumber: '',  // 구입업체 사업자등록번호
        inAddress: '',  // 구입업체 주소
        outAmount: 0,  // 출고령
        outVendorCd: '',  // 판매업체코드
        outVendorName: '',  // 판매업체
        outCorpRegistrationNumber: '',  // 판매업체 사업자등록번호
        outAddress: '',  // 판매업체 주소
        inventory: 0,  // 재고량
        remarks: '',  // 비고
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    save() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    deleteItem() {
      let selectData = this.$refs['inout-table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let filterData = this.$_.cloneDeep(this.grid2.data);
        this.$_.forEach(selectData, item => {
          filterData = this.$_.reject(filterData, item);
        })

        if (this.$_.findIndex(filterData, (item) => {
          return !item.inoutDate // !item.facilityCd || !item.inoutDate
        }) > -1) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '선택하신 행을 삭제 후 이월량&재고량을 업데이트합니다.\n\r그 과정에서 입출고일이 입력되지 않은 행이 있을 경우 삭제 진행이 되지 않음으로 비어있는 데이터를 입력후 진행바랍니다..', // 선택하신 행을 삭제 후 이월량&재고량을 업데이트합니다.\n\r그 과정에서 입출고일&저장시설이 입력되지 않은 행이 있을 경우 삭제 진행이 되지 않음으로 비어있는 데이터를 입력후 진행바랍니다.
            type: 'warning', // success / info / warning / error
          });
          return;
        }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['inout-table'].$refs['compo-table'].clearSelection();
              this.allDataUpdate();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }

    },
    allDataUpdate() {
      /**
       * 1. 입출고일 기준으로 오름차순 정렬
       * 2. 재고량 & 이월량 업데이트
       * 3. 저장
       */
      let splitData = this.$_.sortBy(this.grid2.data, 'inoutDate')
      if (splitData && splitData.length > 0) {
        let idx = 0
        this.$_.forEach(splitData, item => {
          if (idx > 0) {
            this.$set(item, 'carryover', Number(splitData[idx - 1].inventory))
            this.$set(item, 'inventory', Number(splitData[idx - 1].inventory) + Number(item.inAmount) - Number(item.outAmount))
          } else {
            this.$set(item, 'carryover', 0)
            this.$set(item, 'inventory', Number(item.inAmount) - Number(item.outAmount))
          }
          if (item.editFlag !== 'C') {
            this.$set(item, 'editFlag', 'U')
            this.$set(item, 'chgUserId', this.$store.getters.user.userId)
          }
          idx++;
        })
      }

      this.isSave = !this.isSave
    },
    print() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.inout.mdmChemMaterialId || this.popupParam.mdmChemMaterialId,
        yearMonth: this.inout.yearmonth
      };
      this.$http.request(
        _result => {
          let fileOrgNm = this.inout.materialName + '_' + this.inout.yearmonth + ' 보관ㆍ저장  관리대장' + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
<style lang="sass">
.chem-inout-info 
  margin-top: -20px !important
.inout-facility
  display: inline-block
  label
    padding-bottom: 0px !important
  .q-field__inner
    padding-bottom: 0px !important
</style>